.error-message{
  display: none;
}
.error-message.active{
  display: block !important;
  color: red;
}

.error-private{
  display: none;
}
.error-private.active{
  display: block !important;
  color: red;
}